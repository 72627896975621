import {
  IResourceComponentsProps,
  useTranslate,
  useCan,
  CanReturnType,
} from '@refinedev/core'

import { Table, Typography, TableProps } from 'antd'
import { IOperation } from 'interfaces'
import { formattedAmount, formattedCardNumber } from 'utils'
import { OperationStatus } from 'pages/cashin/OperationStatus'

import DateField from 'components/DateField'
import { ShortId } from 'components/ShortId'
import { OperationAttachments } from 'pages/operations/OperationAttachments'
import { OperationActions } from 'pages/operations/OperationActions'
import dayjs from 'dayjs'

const { Text } = Typography

const OperationsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IOperation>
    canViewMerchants: CanReturnType | undefined
  }
> = ({ tableProps, canViewMerchants }) => {
  const t = useTranslate()

  const { data: canViewPartners } = useCan({
    resource: 'partners',
    action: 'filter',
  })

  const { data: canApproveOperation } = useCan({
    resource: 'operations',
    action: 'approve',
  })

  const { data: canViewFee } = useCan({
    resource: 'operations',
    action: 'view_fee',
  })

  return (
    <Table
      scroll={{ x: '100%' }}
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column
        ellipsis={true}
        dataIndex="id"
        key="id"
        title={t('operations.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column
        width={10}
        dataIndex="status"
        key="status"
        title={t('operations.fields.status')}
        render={(value) => <OperationStatus status={value} />}
      />
      <Table.Column
        width={50}
        dataIndex="idempotency_key"
        key="idempotency_key"
        title={t('operations.fields.idempotency_key')}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'currency']}
        key="currency"
        title={t('operations.fields.currency')}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="amount"
        key="amount"
        title={t('operations.fields.amount')}
        render={formattedAmount}
      />
      <Table.Column
        width={50}
        align="right"
        dataIndex="approved_amount"
        key="approved_amount"
        title={t('operations.fields.approved_amount')}
        render={formattedAmount}
      />
      {canViewFee?.can && (
        <Table.Column<IOperation>
          width={50}
          align="right"
          dataIndex="fee"
          key="fee"
          title={t('operations.fields.fee')}
          render={formattedAmount}
        />
      )}
      {canViewMerchants?.can && (
        <Table.Column
          ellipsis
          dataIndex={['merchant', 'name']}
          key="merchant"
          title={t('operations.fields.merchant')}
        />
      )}
      <Table.Column
        ellipsis
        dataIndex={'bank_name'}
        key="bank_name"
        title={t('operations.fields.bank')}
      />
      <Table.Column
        ellipsis
        dataIndex={'payout_requisite'}
        key="payout_requisite"
        title={t('operations.fields.payout_requisite')}
        render={(value) => {
          return value?.sbp?.phone ? (
            <Text code copyable>
              {value?.sbp?.phone}
            </Text>
          ) : (
            <Text code copyable>
              {value?.card_number}
            </Text>
          )
        }}
      />
      <Table.Column
        ellipsis
        dataIndex={['payment_requisite', 'card_number']}
        key="card"
        title={t('operations.fields.card')}
        render={(value) => formattedCardNumber(value)}
      />
      <Table.Column
        ellipsis
        dataIndex={['gateway', 'name']}
        key="gateway"
        title={t('operations.fields.gateway')}
      />
      {canViewPartners?.can && (
        <Table.Column<IOperation>
          ellipsis
          dataIndex={['partner', 'name']}
          key="partner"
          title={t('operations.fields.partner')}
        />
      )}
      <Table.Column
        ellipsis={true}
        dataIndex="trade_order_id"
        key="trade_order_id"
        title={t('operations.fields.trade_order_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column
        dataIndex="created_at"
        ellipsis={true}
        width={10}
        key="created_at"
        title={t('operations.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IOperation>
        dataIndex="duration"
        ellipsis={true}
        width={10}
        key="duration"
        title={t('operations.fields.duration')}
        render={(_, record) => {
          if (record.status === 'created') {
            //@ts-ignore
            return Math.round((new Date() - new Date(record.created_at)) / 60 / 1000)
          } else {
            return ''
          }
        }}
      />
      <Table.Column<IOperation>
        dataIndex="timeout"
        ellipsis={true}
        width={10}
        key="timeout"
        title={t('operations.fields.timeout')}
      />
      <Table.Column
        dataIndex="approved_at"
        ellipsis={true}
        width={10}
        key="approved_at"
        title={t('operations.fields.approved_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IOperation>
        dataIndex="approval_time"
        ellipsis={true}
        align="right"
        key="approval_time"
        title={t('operations.fields.approval_time')}
        render={(value, record) =>
          record.approved_at
            ? dayjs(record.approved_at).diff(dayjs(record.created_at), 'minutes')
            : ''
        }
      />
      <Table.Column<IOperation>
        fixed="right"
        width={100}
        title={t('operations.fields.attachment')}
        dataIndex="actions"
        key="actions"
        align="center"
        render={(_value, record) => (
          <OperationAttachments attachments_ids={record.attachment_ids} />
        )}
      />
      {canApproveOperation?.can && (
        <Table.Column<IOperation>
          fixed="right"
          width={100}
          title={t('operations.fields.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_value, record) => <OperationActions id={record.id} />}
        />
      )}
    </Table>
  )
}

export default OperationsTable
