import { DollarOutlined } from '@ant-design/icons'
import { Button, Modal, Typography, Spin, Table } from 'antd'
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core'
import { useState } from 'react'
import { TextField } from '@refinedev/antd'

import { IOperationMargin, IMargin, IExplanation, IAgreement } from 'interfaces'
import DateField from 'components/DateField'
import TextAmountColor from 'components/TextAmountColor'
import { formattedAmount } from 'utils'

const Margin: React.FC<{ id: string }> = ({ id }) => {
  const apiUrl = useApiUrl()
  const t = useTranslate()

  const { data: remoteData, isLoading } = useCustom<IOperationMargin>({
    url: `${apiUrl}/operations/${id}/margin`,
    method: 'get',
    queryOptions: {
      queryKey: ['operations', 'margin'],
    },
  })

  const operation = remoteData?.data

  return operation && !isLoading ? (
    <>
      <Typography.Title level={4}>Сделка</Typography.Title>
      <Table
        scroll={{ x: '100%' }}
        size="small"
        dataSource={[operation?.margin]}
        pagination={false}
      >
        <Table.Column
          dataIndex="volume"
          key="volume"
          title="Объем"
          render={formattedAmount}
        />
        <Table.Column
          dataIndex="revenue"
          key="revenue"
          title="Комиссия"
          render={formattedAmount}
        />
        <Table.Column
          dataIndex="cost"
          key="cost"
          title="Расходы"
          render={formattedAmount}
        />
        <Table.Column<IOperationMargin>
          key="prifit"
          title="Профит"
          render={(margin: IMargin) => (
            <TextAmountColor
              value={formattedAmount(
                parseFloat(margin?.revenue) - parseFloat(margin?.cost)
              )}
            />
          )}
        />
      </Table>

      <br />

      <Typography.Title level={4}>Баланс</Typography.Title>
      <Table
        scroll={{ x: '100%' }}
        size="small"
        dataSource={operation?.money_transactions}
        pagination={false}
      >
        <Table.Column
          dataIndex="merchant_balance_diff"
          key="merchant_balance_diff"
          title="Мерчант"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="team_deposit_balance_diff"
          key="team_deposit_balance_diff"
          title="Команда"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="shift_balance_diff"
          key="shift_balance_diff"
          title="Смена"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="payment_requisite_cash_limit_balance_diff"
          key="payment_requisite_cash_limit_balance_diff"
          title="Реквизит"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="feed_balance_diff"
          key="feed_balance_diff"
          title="Feed"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="trade_order_diff"
          key="trade_order_diff"
          title="Трейдинг"
          render={(value) => <TextAmountColor value={value} style={{ fontSize: 12 }} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title="Создан"
          render={(value) => (
            <DateField
              value={value}
              format="DD.MM.YYYY, HH:mm:ss"
              style={{ fontSize: 12 }}
            />
          )}
        />
      </Table>

      <br />

      <Typography.Title level={4}>Детали</Typography.Title>
      <Table
        scroll={{ x: '100%' }}
        size="small"
        dataSource={operation?.explanations}
        pagination={false}
      >
        <Table.Column
          dataIndex="value"
          key="value"
          title="Сумма"
          render={(value) => (
            <TextField
              value={formattedAmount(value)}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'agreement'}
          key="type"
          title="Соглашение"
          render={(agreement?: IAgreement) => (
            <TextField
              value={t(`agreements.types.${agreement?.type}`)}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'agreement'}
          key="percent"
          title="Процент"
          render={(agreement?: IAgreement) => (
            <TextField
              value={agreement?.percent}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'agreement'}
          key="gateway"
          title="Гейтвей"
          render={(agreement?: IAgreement) => (
            <TextField
              style={{ color: agreement?.gateway?.name ? '' : 'grey', fontSize: 12 }}
              value={agreement?.gateway?.name ? agreement?.gateway?.name : 'Не указан'}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'agreement'}
          key="valid_from"
          title="Действует с"
          render={(agreement?: IAgreement) => (
            <TextField
              value={agreement?.valid_from}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'agreement'}
          key="valid_to"
          title="Действует по"
          render={(agreement?: IAgreement) => (
            <TextField
              value={agreement?.valid_to}
              style={{ fontSize: 12 }}
            />
          )}
        />
      </Table>

      <br />

      <Typography.Title level={4}>Трейдинг</Typography.Title>
      <Table
        scroll={{ x: '100%' }}
        size="small"
        dataSource={operation?.explanations}
        pagination={false}
      >
        <Table.Column
          dataIndex="value"
          key="value"
          title="Сумма"
          render={(value) => (
            <TextField
              value={formattedAmount(value)}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'trade_order'}
          key="kind"
          title="Тип"
          render={(trade_order?: any) => (
            <TextField
              value={trade_order?.kind}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'trade_order'}
          key="percent"
          title="Процент"
          render={(trade_order?: any) => (
            <TextField
              value={trade_order?.percent}
              style={{ fontSize: 12 }}
            />
          )}
        />
        <Table.Column<IExplanation>
          dataIndex={'trade_order'}
          key="gateway"
          title="Гейтвей"
          render={(trade_order?: any) => (
            <TextField
              style={{ color: trade_order?.gateway?.name ? '' : 'grey', fontSize: 12 }}
              value={trade_order?.gateway?.name ? trade_order?.gateway?.name : 'Не указан'}
            />
          )}
        />
      </Table>
    </>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin tip="Loading" size="large" />
    </div>
  )
}

export const OperationMargin: React.FC<{ id: string }> = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button icon={<DollarOutlined />} size="small" onClick={showModal} />
      <Modal width={800} open={isModalOpen} onCancel={closeModal} footer={null} destroyOnClose={true}>
        <Margin id={id}></Margin>
      </Modal>
    </>
  )
}
